import { nextApiRequest } from './utils';
import { ClassificationItem } from '../common/documents/ClassificationDocument';

export type WWWClassificationItem = ClassificationItem & {
  id: string;
  parentID?: string | null;
};

export type WWWClassificationState = {
  contractingForms: WWWClassificationItem[];
  languages: WWWClassificationItem[];
  machineryTypes: WWWClassificationItem[];
  operationalAreas: WWWClassificationItem[];
  projectTypes: WWWClassificationItem[];
  taitoCompetences: WWWClassificationItem[];
  talo80: WWWClassificationItem[];
  targetGroups: WWWClassificationItem[];
  tol2008: WWWClassificationItem[];
};

/**
 * Fetch and parse contracting forms
 */
export const fetchContractingForm = async (): Promise<Pick<WWWClassificationState, 'contractingForms'>> => {
  const targetGroupsRequest = (await nextApiRequest('/api/v1/www/classifications/contracting-forms')) || {
    items: [],
  };

  return {
    contractingForms: targetGroupsRequest.items || [],
  };
};

/**
 * Fetch and parse languages
 */
export const fetchLanguages = async (): Promise<Pick<WWWClassificationState, 'languages'>> => {
  const languagesRequest = (await nextApiRequest('/api/v1/www/classifications/languages')) || {
    items: [],
  };

  return {
    languages: languagesRequest.items || [],
  };
};

/**
 * Fetch and parse machinery types
 */
export const fetchMachineryTypes = async (): Promise<Pick<WWWClassificationState, 'machineryTypes'>> => {
  const machineryTypesRequest = (await nextApiRequest('/api/v1/www/classifications/machinery-types')) || {
    items: [],
  };

  return {
    machineryTypes: machineryTypesRequest.items || [],
  };
};

/**
 * Fetch and parse operational areas
 */
export const fetchOperationalAreas = async (): Promise<Pick<WWWClassificationState, 'operationalAreas'>> => {
  const operationalAreasRequest = (await nextApiRequest('/api/v1/www/classifications/operational-areas')) || {
    items: [],
  };

  return {
    operationalAreas: operationalAreasRequest.items || [],
  };
};

/**
 * Fetch and parse project types
 */
export const fetchProjectTypes = async (): Promise<Pick<WWWClassificationState, 'projectTypes'>> => {
  const projectTypesRequest = (await nextApiRequest('/api/v1/www/classifications/project-types')) || {
    items: [],
  };

  return {
    projectTypes: projectTypesRequest.items || [],
  };
};

/**
 * Fetch and parse taito competences
 */
export const fetchTaitoCompetences = async (): Promise<Pick<WWWClassificationState, 'taitoCompetences'>> => {
  const taitoCompetencesRequest = (await nextApiRequest('/api/v1/www/classifications/taito-competences')) || {
    items: [],
  };

  return {
    taitoCompetences: taitoCompetencesRequest.items || [],
  };
};

/**
 * Fetch and parse Talo 80
 */
export const fetchTalo80 = async (): Promise<Pick<WWWClassificationState, 'talo80'>> => {
  const talo80Request = (await nextApiRequest('/api/v1/www/classifications/talo-80')) || {
    items: [],
  };

  return {
    talo80: talo80Request.items || [],
  };
};

/**
 * Fetch and parse target groups
 */
export const fetchTargetGroup = async (): Promise<Pick<WWWClassificationState, 'targetGroups'>> => {
  const targetGroupsRequest = (await nextApiRequest('/api/v1/www/classifications/target-groups')) || {
    items: [],
  };

  return {
    targetGroups: targetGroupsRequest.items || [],
  };
};

/**
 * Fetch and parse tol 2008
 */
export const fetchTol2008 = async (): Promise<Pick<WWWClassificationState, 'tol2008'>> => {
  const tol2008Request = (await nextApiRequest('/api/v1/www/classifications/tol-2008')) || {
    items: [],
  };

  return {
    tol2008: tol2008Request.items || [],
  };
};

/** A map of all search options and a fetcher to load them */
export const classificationFetchers: { [T in keyof WWWClassificationState]: () => Promise<Pick<WWWClassificationState, T>> } = {
  contractingForms: fetchContractingForm,
  languages: fetchLanguages,
  machineryTypes: fetchMachineryTypes,
  operationalAreas: fetchOperationalAreas,
  projectTypes: fetchProjectTypes,
  taitoCompetences: fetchTaitoCompetences,
  talo80: fetchTalo80,
  targetGroups: fetchTargetGroup,
  tol2008: fetchTol2008,
};
